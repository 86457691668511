
import { useStore } from "vuex";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "chat-user-search-field",
  setup(props, {emit}) {
    const store = useStore();
    const inputValue = ref("");
    function handleSelect(event) {
      emit("selectUser", event);
    }
    function searchUsers(query: string, callBack: (arg) => void) {
      store.dispatch("LiveChatModule/searchUsers", query).then(result => {
        callBack(
          result.map(item => {
            return {
              value: item.name,
              userId: item.id
            };
          })
        );
      });
    }
    return {
      handleSelect,
      inputValue,
      searchUsers
    }
  }
});
