
import { useStore } from "vuex";
import { computed, defineComponent, PropType } from "vue";
import { ChatHistoryEntryObject } from "@/components/live-chat/ts/types";

interface ChatHistoryEntry {
  name: string;
  subText: string;
  lastMessage: string | null;
  photo: string | null;
  unreadMessages: number;
}

export default defineComponent({
  name: "chat-history-entry",
  props: {
    data: {
      type: Object as PropType<ChatHistoryEntryObject>,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const chatData = computed<ChatHistoryEntry | null>(() => {
      if (props.data.is_group == 0) {
        const currentUser = store.getters.currentUser;
        if (currentUser) {
          const participant = props.data.participants.find(
            item => item.id != currentUser.id
          );
          if (participant) {
            return {
              name: participant.name,
              subText: participant.email,
              photo: participant.photo,
              lastMessage: props.data.last_message,
              unreadMessages: props.data.unread_messages
            };
          }
          return null;
        }
      } else {
        return {
          name: props.data.name == null ? "Group chat" : props.data.name,
          subText: `${props.data.participants.length} ${props.data.participants.length == 1 ? 'participant' : 'participants'}`,
          photo:
            "https://eu.ui-avatars.com/api/?name=" +
            (props.data.name == null ? "Group chat" : props.data.name),
          lastMessage: props.data.last_message,
          unreadMessages: props.data.unread_messages
        };
      }
      return null;
    });
    return {
      chatData
    };
  }
});
