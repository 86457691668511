import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-between py-4"
}
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "symbol symbol-45px symbol-circle" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "ms-5" }
const _hoisted_6 = { class: "fw-semobold text-gray-400" }
const _hoisted_7 = { class: "d-flex align-items-center" }
const _hoisted_8 = {
  key: 0,
  class: "badge badge-sm badge-circle badge-light-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.chatData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.chatData.photo
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (
            _ctx.$emit('changeSelectedChat', {
              id: _ctx.data.id,
              chatName: _ctx.chatData.name,
              participants: _ctx.data.is_group == 1 ? _ctx.data.participants : null
            })
          )),
              class: "cursor-pointer user-select-none fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
            }, _toDisplayString(_ctx.chatData.name), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.chatData.subText), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.chatData.unreadMessages)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.chatData.unreadMessages), 1))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}