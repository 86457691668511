import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c70d86d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomChatLoading = _resolveComponent("CustomChatLoading")!
  const _component_UserMessage = _resolveComponent("UserMessage")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_skeleton, {
      style: {"height":"600px"},
      loading: _ctx.loading,
      animated: ""
    }, {
      template: _withCtx(() => [
        _createVNode(_component_CustomChatLoading)
      ]),
      default: _withCtx(() => [
        (_ctx.messages?.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(`scroll-y me-n5 pe-5 ${_ctx.isDrawer ? 'h-400px' : 'chat-height'}`),
              ref: "scrollContainer"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedMessages, (message) => {
                return (_openBlock(), _createBlock(_component_UserMessage, {
                  class: "user-message",
                  key: message.id,
                  "is-own": message.user.id == _ctx.currentUser.id,
                  messageData: message
                }, null, 8, ["is-own", "messageData"]))
              }), 128))
            ], 2))
          : (_openBlock(), _createBlock(_component_el_empty, {
              key: 1,
              description: "Write a message to begin conversation"
            }))
      ]),
      _: 1
    }, 8, ["loading"])
  ]))
}