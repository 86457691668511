import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!

  return (_openBlock(), _createBlock(_component_el_autocomplete, {
    class: "w-100",
    placeholder: "Search user by name",
    modelValue: _ctx.inputValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
    debounce: 400,
    size: "large",
    teleported: false,
    onSelect: _ctx.handleSelect,
    "fetch-suggestions": _ctx.searchUsers
  }, null, 8, ["modelValue", "onSelect", "fetch-suggestions"]))
}