import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column justify-content-start mt-4" }
const _hoisted_2 = { class: "d-flex flex-column justify-content-end align-items-end mt-4" }
const _hoisted_3 = { class: "d-flex flex-column justify-content-start mt-4" }
const _hoisted_4 = { class: "d-flex flex-column justify-content-end align-items-end mt-4" }
const _hoisted_5 = { class: "d-flex flex-column justify-content-start mt-4" }
const _hoisted_6 = { class: "d-flex flex-column justify-content-end align-items-end mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_skeleton_item, {
        variant: "text",
        class: "mb-3",
        style: {"width":"25%"}
      }),
      _createVNode(_component_el_skeleton_item, { style: {"width":"35%","height":"60px"} })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_skeleton_item, {
        variant: "text",
        class: "mb-3",
        style: {"width":"25%"}
      }),
      _createVNode(_component_el_skeleton_item, { style: {"width":"35%","height":"60px"} })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_skeleton_item, {
        variant: "text",
        class: "mb-3",
        style: {"width":"25%"}
      }),
      _createVNode(_component_el_skeleton_item, { style: {"width":"35%","height":"60px"} })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_skeleton_item, {
        variant: "text",
        class: "mb-3",
        style: {"width":"25%"}
      }),
      _createVNode(_component_el_skeleton_item, { style: {"width":"35%","height":"60px"} })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_el_skeleton_item, {
        variant: "text",
        class: "mb-3",
        style: {"width":"25%"}
      }),
      _createVNode(_component_el_skeleton_item, { style: {"width":"35%","height":"60px"} })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_el_skeleton_item, {
        variant: "text",
        class: "mb-3",
        style: {"width":"25%"}
      }),
      _createVNode(_component_el_skeleton_item, { style: {"width":"35%","height":"60px"} })
    ])
  ], 64))
}