
import { computed, defineComponent, PropType } from "vue";
import CloseIcon from "@/components/Icons/CloseIcon.vue";
import { Participant } from "@/components/live-chat/ts/types";
import IconRightArrow from "@/components/Icons/IconRightArrow.vue";

export default defineComponent({
  name: "chat-window-header",
  components: { IconRightArrow },
  props: {
    userName: {
      type: String as PropType<string>,
      default: () => ""
    },
    isDrawer: {
      type: Boolean as PropType<boolean>,
      default: () => false
    },
    participants: {
      type: [Array, null] as PropType<Participant[] | null>,
      default: () => null
    }
  }
});
