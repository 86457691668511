
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  reactive
} from "vue";
export default defineComponent({
  name: "send-message-box",
  props: {
    isSending: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    isDrawer: {
      type: Boolean as PropType<boolean>,
      default: () => false
    }
  },
  setup(props, { emit }) {
    const data = reactive({
      message: ""
    });
    onMounted(() => {
      const input = document.getElementById(
        props.isDrawer ? "sendMessageInputDrawer" : "sendMessageInput"
      );
      if (input) {
        input.focus();
      }
    });
    const hasMessage = computed<boolean>(() => "" === data.message.trim());
    function handleSendMessage(): void {
      if (props.isSending) {
        return;
      }

      if (!hasMessage.value) {
        emit("sendMessage", data.message);
      }
    }
    function clearField(): void {
      data.message = "";
      const input = document.getElementById(
        props.isDrawer ? "sendMessageInputDrawer" : "sendMessageInput"
      );
      if (input) {
        input.focus();
      }
    }
    return {
      handleSendMessage,
      clearField,
      hasMessage,
      data
    };
  }
});
