
import { defineComponent, PropType } from "vue";
import {
  chatMessage,
  ChatMessageObject
} from "@/components/live-chat/ts/types";
import moment from "moment";

export default defineComponent({
  name: "user-message",
  props: {
    isOwn: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    messageData: {
      type: Object as PropType<ChatMessageObject>,
      required: true
    }
  },
  setup() {
    function getHumanFormatFromDateString(dateString: string): string {
      const timeOfMessage = moment.utc(dateString);

      if (!timeOfMessage.isValid()) {
        return "";
      }

      const now = moment.utc();
      const hoursDifference = now.diff(timeOfMessage, "hours");

      if (hoursDifference < 1) {
        const minutes = now.diff(timeOfMessage, "minutes");
        const minuteString = minutes <= 1 ? "minute ago" : "minutes ago";
        return `${minutes < 1 ? 1 : minutes} ${minuteString}`;
      } else if (hoursDifference < 24) {
        return hoursDifference === 1
          ? `${hoursDifference} hour ago`
          : `${hoursDifference} hours ago`;
      } else {
        return timeOfMessage.local().format("MMMM Do, YYYY [at] h:mm A");
      }
    }
    return {
      getHumanFormatFromDateString
    };
  }
});
