
import { useStore } from "vuex";
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import { ChatHistoryEntryObject } from "@/components/live-chat/ts/types";
import ChatUserSearchField from "@/components/live-chat/custom/ChatUserSearchField.vue";
import ChatHistoryEntry from "@/components/live-chat/ui-fragments/ChatHistoryEntry.vue";

export default defineComponent({
  name: "chat-history",
  components: {
    ChatUserSearchField,
    LoadingSpinner,
    ChatHistoryEntry
  },
  props: {
    isDrawer: {
      type: Boolean as PropType<boolean>,
      default: () => false
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const loading = ref<boolean>(false);
    const history = computed<ChatHistoryEntryObject[]>(() => {
      const chatHistory = store.getters["LiveChatModule/getChatHistory"];
      return chatHistory.slice().sort((a, b) => {
        if (a.last_message === null && b.last_message === null) {
          return 0;
        }
        if (a.last_message === null) {
          return 1;
        }
        if (b.last_message === null) {
          return -1;
        }
        return b.last_message.localeCompare(a.last_message);
      });
    });

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    function loadChatHistory() {
      setLoading(true);
      store.dispatch("LiveChatModule/fetchChatHistory").finally(() => {
        setLoading(false);
      });
    }
    function handleSelectUser(eventData: { value: string; userId: number }) {
      emit("changeSelectedChat", {
        chatName: eventData.value,
        userId: eventData.userId,
        chatId: null,
        participants: null
      });
    }
    onMounted(() => {
      loadChatHistory();
    });
    return {
      handleSelectUser,
      setLoading,
      loading,
      history
    };
  }
});
