
/* eslint-disable @typescript-eslint/camelcase */
import { useStore } from "vuex";
import {
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  reactive,
  ref
} from "vue";
import MessagesWindow from "@/components/live-chat/ui-fragments/MessagesWindow.vue";
import SendMessageBox from "@/components/live-chat/ui-fragments/SendMessageBox.vue";
import {
  WebSocketNewMessageEvent,
  ChatMessageObject,
  SelectChatEvent
} from "@/components/live-chat/ts/types";
import ChatWindowHeader from "@/components/live-chat/ui-fragments/ChatWindowHeader.vue";
import { eventBus } from "@/pusher.js";

export default defineComponent({
  name: "chat-window",
  components: { ChatWindowHeader, SendMessageBox, MessagesWindow },
  props: {
    data: {
      type: [Object, null] as PropType<SelectChatEvent | null>,
      default: () => null
    },
    isDrawer: {
      type: Boolean as PropType<boolean>,
      default: () => false
    }
  },
  setup: function(props, { emit }) {
    const store = useStore();
    const isSendingMessage = ref<boolean>(false);
    const messageBoxKey = ref<number>(0);
    const sendMessageBox = ref<InstanceType<typeof SendMessageBox>>();
    const chatMessages = ref<null | ChatMessageObject[]>(null);
    const loading = ref<boolean>(false);
    const pagination = reactive({
      current_page: 1,
      last_page: 1
    });
    eventBus.on("MessageReadByUser", (chatId: number) => {
      if (chatId && chatId == props.data?.chatId) {
        chatMessages.value?.forEach(el => {
          el.is_read = true;
        });
      }
    });
    eventBus.on("NewChatMessage", (eventData: WebSocketNewMessageEvent) => {
      if (eventData && props.data?.chatId && null != chatMessages.value) {
        if (props.data.chatId === eventData.message.chat_id) {
          chatMessages.value.push({
            is_read: false,
            id: eventData.message.id,
            created_at: eventData.message.created_at,
            user: eventData.sender,
            body: eventData.message.body,
            updated_at: eventData.message.updated_at,
            is_group: eventData.is_group_chat
          });
        }
      }
    });

    function fetchChatMessages(triggerLoading) {
      if (null != props.data) {
        if (null != props.data.chatId) {
          loading.value = triggerLoading;
          store
            .dispatch("LiveChatModule/fetchConversation", {
              chat_id: props.data.chatId,
              page: pagination.current_page
            })
            .then(response => {
              if (pagination.current_page == 1) {
                chatMessages.value = response.data.reverse();
              } else {
                response.data.reverse().forEach(element => {
                  if (chatMessages.value) {
                    chatMessages.value.unshift(element);
                  }
                });
              }
              pagination.last_page = response.meta.last_page;
              loading.value = false;
            });
        } else {
          chatMessages.value = []; //handleUserChat
        }
      }
    }

    function handleLoadOlderMessages() {
      if (pagination.current_page + 1 <= pagination.last_page) {
        pagination.current_page++;
        fetchChatMessages(false);
      }
    }

    onMounted(() => {
      fetchChatMessages(true);
    });

    function handleSendMessage(messageBody: string): void {
      isSendingMessage.value = true;
      store
        .dispatch("LiveChatModule/sendMessage", {
          ...props.data,
          messageBody: messageBody
        })
        .then(response => {
          if (pagination.current_page == 1) {
            chatMessages.value = response.data.messages.reverse();
          } else {
            if (chatMessages.value) {
              chatMessages.value.push(response.data.messages[0]);
            }
          }
          sendMessageBox.value?.clearField();
          if (null === props.data?.chatId) {
            emit("newChatCreated", response.data.chat);
          } else {
            emit("updateLatestChat", response.data.chat);
          }
        })
        .finally(() => {
          isSendingMessage.value = false;
        });
    }

    return {
      handleLoadOlderMessages,
      handleSendMessage,
      isSendingMessage,
      sendMessageBox,
      messageBoxKey,
      chatMessages,
      pagination,
      loading
    };
  }
});
