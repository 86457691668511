import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_ChatWindowHeader = _resolveComponent("ChatWindowHeader")!
  const _component_MessagesWindow = _resolveComponent("MessagesWindow")!
  const _component_SendMessageBox = _resolveComponent("SendMessageBox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`flex-lg-row-fluid ${_ctx.isDrawer ? '' : 'ms-lg-7 ms-xl-10'}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.data == null)
        ? (_openBlock(), _createBlock(_component_el_empty, {
            key: 0,
            description: "Select a user to start conversation"
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ChatWindowHeader, {
              "user-name": _ctx.data.chatName,
              "is-drawer": _ctx.isDrawer,
              class: _normalizeClass(_ctx.isDrawer ? 'px-0' : ''),
              onBackToHistory: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('backToHistory'))),
              onCloseDrawerChat: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeDrawerChat'))),
              participants: _ctx.data?.participants
            }, null, 8, ["user-name", "is-drawer", "class", "participants"]),
            _createVNode(_component_MessagesWindow, {
              "is-drawer": _ctx.isDrawer,
              onLoadOlderMessages: _ctx.handleLoadOlderMessages,
              class: _normalizeClass(_ctx.isDrawer ? 'px-0' : ''),
              messages: _ctx.chatMessages,
              "current-page": _ctx.pagination.current_page,
              "chat-id": _ctx.data?.chatId,
              loading: _ctx.loading
            }, null, 8, ["is-drawer", "onLoadOlderMessages", "class", "messages", "current-page", "chat-id", "loading"]),
            (!_ctx.loading)
              ? (_openBlock(), _createBlock(_component_SendMessageBox, {
                  key: _ctx.messageBoxKey,
                  ref: "sendMessageBox",
                  class: _normalizeClass(_ctx.isDrawer ? 'px-0' : ''),
                  "is-drawer": _ctx.isDrawer,
                  onSendMessage: _ctx.handleSendMessage,
                  "is-sending": _ctx.isSendingMessage
                }, null, 8, ["class", "is-drawer", "onSendMessage", "is-sending"]))
              : _createCommentVNode("", true)
          ]))
    ])
  ], 2))
}