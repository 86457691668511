
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable vue/no-mutating-props */
import { useStore } from "vuex";
import {
  defineComponent,
  computed,
  ref,
  PropType,
  watch,
  nextTick,
  onMounted
} from "vue";
import { ChatMessageObject } from "@/components/live-chat/ts/types";
import UserMessage from "@/components/live-chat/ui-fragments/UserMessage.vue";
import CustomChatLoading from "@/components/live-chat/custom/CustomChatLoading.vue";
import moment from "moment";
import { setReadStatus } from "@/pusher.js";
import { debounce } from "lodash";

export default defineComponent({
  name: "messages-window",
  components: {
    CustomChatLoading,
    UserMessage
  },
  props: {
    messages: {
      type: Array as PropType<ChatMessageObject[] | null>,
      default: () => []
    },
    isDrawer: {
      type: Boolean as PropType<boolean>,
      default: () => false
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    currentPage: {
      type: Number as PropType<number>,
      default: () => 1
    },
    chatId: {
      type: [Number, null] as PropType<number | null>,
      default: () => null
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const currentUser = useStore().getters.currentUser;
    const scrollContainer = ref<HTMLElement | null>(null);
    function scrollToBottom() {
      if (scrollContainer.value != null && props.currentPage == 1) {
        scrollContainer.value.scrollTop = scrollContainer.value.scrollHeight;
      } else if (scrollContainer.value != null && props.currentPage > 1) {
        scrollContainer.value.scrollTop =
          scrollContainer.value.scrollHeight * 0.05;
      }
    }
    const handleScroll = debounce(() => {
      if (!scrollContainer.value) return;
      if (scrollContainer.value.scrollTop === 0) {
        emit("loadOlderMessages");
      }
    }, 100);

    function loadScrollToTopListener() {
      if (scrollContainer.value) {
        scrollContainer.value.addEventListener("scroll", handleScroll);
      }
    }

    function handleMarkAsRead(): void {
      if (currentUser && props.messages?.length) {
        const hasUnread =
          props.messages.filter(
            (item: ChatMessageObject) =>
              !item.is_read && item.user.id != currentUser.id
          ).length > 0;
        if (hasUnread) {
          setReadStatus(currentUser.id, props.chatId);
        }
      }
    }

    watch(
      () => scrollContainer.value,
      () => {
        loadScrollToTopListener();
      }
    );

    const groupedMessages = computed<ChatMessageObject[]>(() => {
      if (!props.messages) {
        return [];
      }
      const groupedMessages = [] as ChatMessageObject[];
      let lastMessage: ChatMessageObject | null = null;
      for (const message of props.messages) {
        if (!lastMessage || lastMessage.user.id !== message.user.id) {
          groupedMessages.push(message);
        } else {
          const timeDifferenceInSeconds = moment
            .utc(message.created_at)
            .diff(moment.utc(lastMessage.created_at), "second");
          if (timeDifferenceInSeconds < 300) {
            message.is_group = true;
            groupedMessages.push(message);
          } else {
            groupedMessages.push(message);
          }
        }
        lastMessage = message;
      }
      return groupedMessages;
    });
    watch(groupedMessages, () => {
      handleMarkAsRead();
      console.log(groupedMessages.value);
      nextTick().then(() => {
        scrollToBottom();
        if (props.currentPage > 1) {
          loadScrollToTopListener();
        }
      });
    });
    return {
      groupedMessages,
      scrollContainer,
      currentUser
    };
  }
});
